var Sliders = {};
var windowWidth = 0;
var heroSliderPod;

Sliders.init = function() {
	this.Slider();
};

Sliders.Slider = function() {	
	heroSliderPod = $('.hero-slider-pod .slider')

	heroSliderPod.on('init', function (slick) {
		var play = $(this).parent().find('.controls button[data-action="play"]');
		var pause = $(this).parent().find('.controls button[data-action="pause"]');
		play.on('click', function () {
			heroSliderPod.slick('slickSetOption', "focusOnChange", false)
				.slick('slickPlay');
			play.parent().find('[data-action="play"]').hide();
			play.parent().find('[data-action="pause"]').show();
		});
		pause.on('click', function () {
			heroSliderPod.slick('slickSetOption', "focusOnChange", true)
				.slick('slickPause');
			pause.parent().find('[data-action="play"]').show();
			pause.parent().find('[data-action="pause"]').hide();
		});
	});

	if (heroSliderPod.length > 0) {
		heroSliderPod.slick({
			rows: 0, //gets rid of additional div in slider
			dots: true,
			autoplay: true,
			autoplaySpeed: 6000,
			focusOnChange: false,
			prevArrow: '<span class="arrow prev"></span>',
			nextArrow: '<span class="arrow next"></span>',
			//appendArrows: $('.hero-slider-pod .arrows .row'),
			//appendDots: $('.hero-slider-pod .dots .row .dots-wrapper'),
			responsive: [
				{
					breakpoint: 568,
					settings: {
						adaptiveHeight: true
					}
				}
			]
		});

		heroSliderPod.on("focus", ".slick-slide", function () {
			//console.log('focus');
			heroSliderPod.slick('slickSetOption', "focusOnChange", true)
						 .slick('slickPause');
		});

	}
}; 

$(document).ready(function(){
	Sliders.init();
});